import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  max-width: ${(props) => props.theme.containerWidths.medium};
  margin-left: auto;
  margin-right: auto;

  .promotion-container {
    display: flex;
    flex-direction: column;
  }

  .promotion-text {
    flex-shrink: 2;
    align-self: flex-start;
  }

  .palette-img {
    padding: 5px 10px;
    max-width: 350px;
    margin: 24px auto 0;
  }

  .padded-section {
    p {
      + p {
        margin-top: 8px;
      }

      + div {
        margin-top: 22px;
      }
    }
  }

  .gatsby-image-wrapper {
    margin-top: -10px;
    margin-bottom: 20px;
  }

  @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
    .promotion-container {
      flex-direction: row;
      align-items: center;
    }

    .promotion-text {
      margin-right: 10px;
    }

    .palette-img {
      margin: auto;
    }

    section {
      + .padded-section {
        padding-top: 0;
      }
    }
  }
`

const Promotion = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulAsset(
        filter: { description: { eq: "#love-pro-palette-40" } }
      ) {
        edges {
          node {
            id
            title
            file {
              url
            }
          }
        }
      }
    }
  `)

  const paletteImg = data.allContentfulAsset.edges[0]

  return (
    <Wrapper className="wrap">
      <section className="section">
        <h3 className="headline--reunion center-align">Artist Sign Up Promotion</h3>
        <div className="promotion-container">
          <p className="promotion-text">
            When you genuinely love something and are passionate about it, you want to share it with others!
            We are offering a new Artist sign-up promotion leading up to the virtual meeting to kick off a
            new Artist’s journey with a fresh start and perspective.
            <br /><br />
            New Artists who sign up from 12:01 am, MST on Thursday, January 18th to Thursday, February 1st
            at 11:59pm, MST will receive a LOVE Pro Palette 40 and a LOVE Basic Ticket. This promotion applies
            to NEW Artists signing up with the Basic and Pro Artist Kits in the US and Canada.
          </p>
          <img className="palette-img" src={paletteImg.node.file.url} alt={paletteImg.node.title} />
        </div>
      </section>
    </Wrapper>
  )
}

export default Promotion
